
import { ref } from 'vue'
import { defineStore } from 'pinia'

const localStoragePrefix = location.hostname;
let userInfoData = localStorage.getItem(`${localStoragePrefix}_artmisAdsUserInfo`);
if (userInfoData) {
  userInfoData = JSON.parse(userInfoData);
}

export const useUserInfoStore = defineStore('artmisAdsUserInfo', () => {
  const userInfo = ref(userInfoData);
  function setUserInfo(data, userToken) {
    if (data) {
      userInfo.value = data;
      const userInfoString = JSON.stringify(data);
      localStorage.setItem(`${localStoragePrefix}_artmisAdsUserInfo`, userInfoString);
    } else {
      userInfo.value = null;
      localStorage.setItem(`${localStoragePrefix}_artmisAdsUserInfo`, '');
      localStorage.setItem(`${localStoragePrefix}_userToken`, '')
      localStorage.setItem(`${localStoragePrefix}_tokenTime`, '');
    }
    if (userToken) {
      const time = new Date().getTime();
      localStorage.setItem(`${localStoragePrefix}_userToken`, userToken);
      localStorage.setItem(`${localStoragePrefix}_tokenTime`, time);
    }
  }

  return { userInfo, setUserInfo };
})
